<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_literature_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_literature_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_system_literature_add']" type="primary" @click="onAddLiterature">新增文献</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="文献名称" class="ui-form__item">
						<a-input v-model:value="formModal.fileName"></a-input>
					</a-form-item>
					
					<!-- <a-form-item label="发文部门" class="ui-form__item"> -->
					<a-form-item label="发文单位" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.unitId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="文号" class="ui-form__item">
						<a-input v-model:value="formModal.fileNum"></a-input>
					</a-form-item>
					
					<a-form-item label="所属类型" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.fileTypeId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.literClassify" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="发行时间" class="ui-form__item">
						<a-range-picker show-time :value="clearTime" @change="onChangeFun"></a-range-picker>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'fileName'">文献名称</a-checkbox>
							<a-checkbox :value="'fileNum'">文号</a-checkbox>
							<a-checkbox :value="'fileType'">所属类型</a-checkbox>
							<a-checkbox :value="'releaseTime'">发行时间</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.releaseTime,1) }}
							<!-- {{ record.releaseTime}} -->
						</template>
							<template v-if="column.key === 'remark'">
								<a-tooltip :title="record.remark">
									<div class="remarkStyle">
										{{record.remark}}
									</div>
								</a-tooltip>
							</template>	
						<template v-if="column.key === 'fileName'">
							<a-tooltip :title="record.fileName">
								<div class="fileNameStyle">
									{{record.fileName}}
								</div>
							</a-tooltip>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_literature_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_literature_delete']" type="link" size="small" @click="onDelete(record)" danger>删除</a-button>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="id" class="table" 
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{x: 1300}"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.releaseTime,1) }}
						</template>
							<template v-if="column.key === 'remark'">
								<a-tooltip :title="record.remark">
									<div class="remarkStyle">
										{{record.remark}}
									</div>
								</a-tooltip>
							</template>	
						<template v-if="column.key === 'fileName'">
							<a-tooltip :title="record.fileName">
								<div class="fileNameStyle">
									{{record.fileName}}
								</div>
							</a-tooltip>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_literature_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_literature_delete']" type="link" size="small" @click="onDelete(record)" danger>删除</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<saveModal ref="saveModalRef" @refresh="onReFresh"></saveModal>
	</div>
</template>

<script>
	import moment from 'moment';
	import { getDocuList, deleteDocument } from '@/service/modules/document.js';
	import { Icon } from '@/components/icon/icon.js';
	import saveModal from './components/saveModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, saveModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {},
				list: [],
				getDataFun: getDocuList,
				clearTime: [], // 存储时间 用来清空重置
				testTime: '',
				type: 'fileMangerStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: "index",
					width: 80,
				}, {
					title: '文献名称',
					align: 'center',
					key: 'fileName',
					dataIndex: "fileName"
				}, {
					title: '发行时间',
					align: 'center',
					key: 'time'
					// dataIndex: "createTime"
				}, {
					title: '发文单位',
					align: 'center',
					dataIndex: "unitName"
				}, {
					title: '文号',
					align: 'center',
					dataIndex: "fileNum"
				}, {
					title: '内容摘要',
					align: 'center',
					key: 'remark',
					// dataIndex: "remark"
				}, {
					title:'操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 150
				}],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.clearTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getDocuList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("lit",ret)
			// 			// this.list = ret.data.list;
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onAddLiterature() {
				this.$refs.saveModalRef.open(0);
			},
			onEdit(item) {
				this.$refs.saveModalRef.open(item);
			},
			onReFresh() {
				this.onSearch()
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: "确定删除吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteDocument({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.$nextTick(() => {
									this.$refs.cTable.toQuery();
								})
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.fileNameStyle, .remarkStyle {
		/* height: 150px; */
		display:-webkit-box;
		-webkit-box-orient:vertical;
		-webkit-line-clamp:5;
		text-overflow: ellipsis;
		overflow:hidden;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>