<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑文献' : '新增文献'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
					<a-form-item name="fileName" label="文献名称" :rules="[{ required: true, message: '请输入文献名称!' }, { min: 1, max: 15, message: '请输入字符为1~15之间的文献名称!'}]">
						<a-input v-model:value="modelRef.fileName" placeholder="请输入文献名称"></a-input>
					</a-form-item>
					
					<a-form-item name="fileTypeId" label="文献类型" :rules="[{ required: true, message: '请选择文献类型!' }]">
						<a-select v-model:value="modelRef.fileTypeId" placeholder="请选择文献类型">
							<a-select-option v-for="(item, index) in listerType" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item name="releaseTime" label="发行时间" :rules="[{ required: true, message: '请选择时间!' }]">
						<a-date-picker v-model:value="modelRef.releaseTime"></a-date-picker>
					</a-form-item>
					
					<a-form-item name="unitId" label="发行单位" :rules="[{ required: true, message: '请选择发行单位!' }]">
						<a-select  v-model:value="modelRef.unitId" placeholder="请选择发行单位">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item name="fileNum" :rules="[{ required: true, message: '请输入文号!' }]"  label="文号">
						<a-input  v-model:value="modelRef.fileNum" placeholder="请输入文号"></a-input>
					</a-form-item>
					
					<a-form-item name="remark" label="内容摘要" :rules="[{ required: true, message: '请选择发行单位!' }, { min: 1, max: 100, message: '请输入长度为1~100之间的内容摘要!'}]">
						<a-textarea v-model:value="modelRef.remark" :rows="5" placeholder="请输入内容摘要"></a-textarea>
					</a-form-item>
					
					<a-form-item name="fileList" required label="文献文件">
						<filesUpload v-model:value="modelRef.fileList" :maxSize="100"></filesUpload>
						<p class="ui-upload__warn">注：建议上传pdf文件，文件大小不超过100M</p>
					</a-form-item>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import moment from 'moment';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import filesUpload from '@/components/upload/filesUpload.vue';
	import { updateDocument, deleteDocument, saveDocument, getDocumentTypeList } from '@/service/modules/document.js';
	export default {
		components: { Icon, modalHeader, filesUpload },
		data() {
			return {
				visible: false,
				isEdit: false,
				listerType: [],
				modelRef: {
					fileList: [],
					tempTime: null
				},
				tempDate: null,
				fileList: []
			}
		},
		created() {
			this.getListerType()
		},
		methods: {
			async getListerType() {
				let ret = await getDocumentTypeList();
				if (ret.code === 200) {
					// this.list = ret.data.list;
					this.listerType = ret.data;
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.fileUrl = this.modelRef.fileList.toLocaleString();
					let temp = this.transDateTime(postData.releaseTime)
					let tempTime = new Date(temp)
					postData.releaseTime = tempTime.valueOf()
					postData.releaseTime = this.moment(postData.releaseTime).unix();
					if(postData.fileUrl.lenght !== 0){
						this.loading = true;
						try {
							let ret;
							if (this.isEdit) {
								ret = await updateDocument(postData);
							} else {
								ret = await saveDocument(postData);
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
								this.$emit('refresh');
								this.close();
							}
						} catch(e) {
							this.loading = false;
						}
					}else{
						this.$message.warning('请上传文献文件')
						// alert("请上传文献文件")
					}
				})
			},
			open(e) {
				if (e && e.id) {
					this.modelRef = {
						id: e.id,
						fileName: e.fileName,
						fileList: [e.fileUrl],
						unitId: Number(e.unitId),
						// unitId: e.unitId,
						fileNum: e.fileNum,
						remark: e.remark,
						fileTypeId: Number(e.fileTypeId),
						// fileTypeId: e.fileTypeId
					}
					this.modelRef.releaseTime = this.moment.unix(e.releaseTime)
					this.isEdit = true;
				} else {
					this.isEdit = false;
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.resetFields();
				this.modelRef = {
					fileList: [],
					releaseTime: null
				}
				this.fileList = []
				this.visible = false;
			},
			// onSelDate(dateString){
			// 	console.log('newval',dateString)
			// 	let temp = this.transDateTime(dateString)
			// 	let tempTime = new Date(temp)
			// 	console.log('newval2',tempTime)
			// 	this.modelRef.releaseTime = tempTime.valueOf()
			// },

		}
	}
</script>

<style scoped>
	.ui-upload__warn {
		margin-top: 6px;
		font-size: 12px;
		color: #999;
	}
</style>